<template>
  <RBreadcrumb v-bind="props">
    <slot />
  </RBreadcrumb>
</template>

<script>
/**
 * RBreadcrumbRouter
 *
 * Very similar to CoreUI's CBreadcrumbRouter,
 * but with some additional logic to allow passing
 * string values to be rendered instead of the route
 * name or meta.label.
 *
 * In order to be rendered in the breadcrumbs, the route
 * config must have a meta.label that resolves to a truthy
 * value.
 *
 * If meta.label is a string, that value will be used.
 * If meta.label is a function, the value returned from it will be used.
 *
 * Similarly, meta.disableLink can be used to render a breadcrumb item
 * as text rather than a CLink componenet.
 *
 * CBreadcrumb docs: https://coreui.io/vue/docs/components/breadcrumb.html#cbreadcrumb-api
 * CLink docs: https://coreui.io/vue/docs/components/link.html#clink-api
 *
 * Usage:
 *
 * const routes = [
 *   {
 *     path: '/1',
 *     name: 'One',
 *     component: ...
 *   },
 *   {
 *     path: '/2',
 *     name: 'Two',
 *     meta: {
 *       label: null
 *     },
 *     component: ...
 *   },
 *   {
 *     path: '/3',
 *     name: 'Three',
 *     meta: {
 *       label: 'Example'
 *     },
 *     component: ...
 *   },
 *   {
 *     path: '/3/:id',
 *     name: 'Four',
 *     meta: {
 *       label: vm => {
 *         return vm.$store.getters.getBreadcrumbValue // 'Custom'
 *       }
 *     },
 *     component: ...
 *   }
 * ]
 *
 *
 * this.$router.push({ name: 'One' })
 * [ ] (not shown)
 *
 * this.$router.push({ name: 'Two' })
 * [ ] (not shown)
 *
 * this.$router.push({ name: 'Three' })
 * [ Example ]
 *
 * this.$router.push({ name: 'Four' }, params: { id: 123 })
 * [ Example / Custom ]
 */
import RBreadcrumb from './RBreadcrumb'

export default {
  name: 'RBreadcrumbRouter',
  components: {
    RBreadcrumb
  },
  props: {
    addClasses: {
      type: [String, Array, Object],
      default: undefined
    },
    addLinkClasses: {
      type: [String, Array, Object],
      default: undefined
    },
    addLastItemClasses: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  computed: {
    items () {
      // Generate breadcrumb items
      return this.$route.matched.map(route => {
        // Base route matches as empty string
        let path = route.name === 'Home' ? '/' : route.path

        // <router-link> doesn't support `path` and `params`
        // together, so we need to inject any parameters
        for (const [param, value] of Object.entries(this.$route.params)) {
          path = path.replace(`:${param}`, value)
        }
        const { label, disableLink } = route.meta
        return {
          to: { path },
          text: typeof label === 'function' ? label(this) : label,
          disabled: typeof disableLink === 'function' ? disableLink(this) : disableLink
        }
      }).filter(route => route.text)
    },
    props () {
      return {
        items: this.items,
        addClasses: this.addClasses,
        addLinkClasses: this.addLinkClasses,
        addLastItemClasses: this.addLastItemClasses
      }
    }
  }
}
</script>
