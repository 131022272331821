<template>
  <ol class="breadcrumb">
    <li
      v-for="(item, index) in linkItems"
      :key="index"
      :class="[ item.addClasses, sharedClasses, addLinkClasses ]"
      role="presentation"
    >
      <CLink
        v-if="!item.disabled"
        v-bind="Object.assign({}, item, { addClasses: null, text: null })"
      >
        {{ item.text }}
      </CLink>
      <span v-else class="disabled" v-text="item.text" />
    </li>
    <li
      v-if="lastItem"
      :class="lastItemClasses"
      role="presentation"
    >
      <!-- span added to enable text styling through classes -->
      <span v-text="lastItem.text" />
    </li>
    <slot />
  </ol>
</template>

<script>
/**
 * RBreadcrumb
 *
 * Modified CoreUI CBreadcrumb component. Any breadcrumb
 * items where `item.disabled` is true will be rendered
 * as a <span> rather than a <CLink>. The last item in `items`
 * will _always_ be displayed as a <span> instead of a link.
 */
export default {
  name: 'RBreadcrumb',
  props: {
    items: {
      type: Array,
      default: undefined
    },
    addClasses: {
      type: [String, Array, Object],
      default: undefined
    },
    addLinkClasses: {
      type: [String, Array, Object],
      default: undefined
    },
    addLastItemClasses: {
      type: [String, Array, Object],
      default: undefined
    }
  },
  computed: {
    lastItem () {
      return this.items ? this.items[this.items.length - 1] : null
    },
    linkItems () {
      return this.items ? this.items.slice(0, -1) : []
    },
    sharedClasses () {
      return [this.addClasses, 'breadcrumb-item']
    },
    lastItemClasses () {
      return [
        'active',
        this.lastItem.addClasses,
        this.sharedClasses,
        this.addLastItemClasses
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  display: inline-block; /* remove extra whitespace */
}
.disabled {
  color: $breadcrumb-active-color;
}
</style>
