<template>
  <CAlert
    class="app-banner"
    v-bind="$attrs"
    @update:show="$emit('update:show', $event)"
  >
    <slot />
  </CAlert>
</template>

<script>
export default {
  name: 'AppBanner'
}
</script>

<style lang="scss" scoped>
$padding: 8px 20px;

.app-banner {
  // CoreUI z-index ranges from 1000-1070
  z-index: 1080;
  width: 100%;
  height: $banner-height;
  margin-bottom: 0;
  padding: $padding;
  border-radius: 0;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 0;
  left: 0;
  font-size: 0.95rem;

  &.alert-danger {
    background-color: #ee5757;
    color: #fff;
  }
  ::v-deep a {
    color: #fff;
  }
  ::v-deep button.close {
    padding: $padding;
  }
}
</style>
