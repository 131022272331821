<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">
        &copy; {{ new Date().getFullYear() }} RoofOverYourHead Marketing Ltd.
      </span>
    </div>
    <div class="ml-auto">
      <span class="mr-1">
        {{ buildInfo.branch }} {{ buildInfo.hash }}
      </span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
  created () {
    if (process.env.BUILD_INFO) {
      this.buildInfo = JSON.parse(process.env.BUILD_INFO)
    }
  }
}
</script>
