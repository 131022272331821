<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-help">
          <CIcon name="md-help" height="30" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem
      target="_blank"
      href="https://docs.postengine.com/?utm_source=rooof-property-portal&utm_medium=referral&utm_campaign=dashboard-reporting"
      @click="mixpanelTrack('Help Documents Click')"
    >
      <CIcon name="md-document" height="20" />
      <span class="ml-2">
        Help Documents
      </span>
    </CDropdownItem>
    <CDropdownItem @click="$store.dispatch('support/open'), mixpanelTrack('Contact Support Click')">
      <CIcon name="md-headset" height="20" />
      <span class="ml-2">
        Contact Support
      </span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Mixpanel from 'mixpanel-browser'

export default {
  name: 'TheHeaderDropdownHelp',
  methods: {
    mixpanelTrack (eventName) {
      if (process.env.NODE_ENV === 'production' && !this.$store.getters['auth/isStaff']) {
        Mixpanel.track(eventName)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-help {
  opacity: $header-icon-opacity-inactive;
}
.show .c-help {
  opacity: $header-icon-opacity-active;
}
</style>
