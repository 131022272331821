<template>
  <div id="sidebar-search-results">
    <div class="sidebar-search-info">
      Results for "{{ searchValue }}"
    </div>

    <div v-if="searchValue.length < 3">
      Waiting...
    </div>

    <RSpinnerOverlay v-else-if="loading" :loading="loading" :opacity="0">
      <div style="min-height: 10rem" />
    </RSpinnerOverlay>

    <div v-else-if="data.length > 0">
      <div v-for="company in data" :key="company.id" class="search-result py-1">
        <div
          class="company"
          :class="{'search-result-link': hasCompany(company.id)}"
          @click="handleNavigation(company)"
        >
          {{ company.human_name }}
        </div>
        <div v-for="property in company.properties" :key="`${company.id}-${property.id}`">
          <div
            class="property search-result-link pl-3"
            @click="handleNavigation(company, property)"
          >
            {{ property.name }}
          </div>
        </div>
      </div>
    </div>

    <div v-else>
      No results, try again.
    </div>
  </div>
</template>

<script>
/**
 * TheSidebarSearchResults
 *
 * Displays company/property search results as a nested
 * list. Each item in the list is a link to the
 * respective company/property overview page if the user
 * has permission to view that object.
 *
 * While searching for results, a loading overlay is
 * shown. This component will only be rendered if
 * the search input is non-empty.
 *
 * Events:
 *
 * @on-select: Triggered when user selects a navigation link
 */
import { RSpinnerOverlay } from '@/components/spinners'

export default {
  name: 'TheSidebarSearchResults',
  components: {
    RSpinnerOverlay
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    searchValue: {
      type: String,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    /**
     * Determines if the target company is available to
     * the current user.
     *
     * @param {Number} cid - company id
     */
    hasCompany (cid) {
      return this.$store.getters['rooof/getCompanyById'](cid) !== undefined
    },
    /**
     * Navigate user to the selected company/property
     * page after storing the object(s) in global state.
     *
     * @param {Object} company
     * @param {Object} [property]
     */
    handleNavigation (company, property = null) {
      // Prevent navigating to company's property list view if we don't have permission
      if (!(property || this.hasCompany(company.id))) {
        return
      }

      if (!property) {
        const location = {
          name: 'PerformanceReport',
          params: {
            cid: company.id
          }
        }
        this.$router.push(location)
        this.$emit('on-select', company.human_name)
      } else {
        const location = {
          name: 'PropertyDetail',
          params: {
            cid: company.id,
            pid: property.id
          }
        }
        this.$router.push(location)
        this.$emit('on-select', property.name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#sidebar-search-results {
  height: calc(100vh - #{$header-height} - #{$subheader-height});
  padding: 1rem;
  overflow-y: auto;

  .sidebar-search-info {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .search-result {
    .search-result-link:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    .company {
      font-weight: bold;
      font-size: 1rem;
      color: $primary;
    }
    .property {
      display: inline-block;
      color: $gray-900;
      font-size: 0.95rem;
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
  }
}
</style>
