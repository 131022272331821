<template>
  <div id="sidebar-search">
    <CInput
      :value="value"
      :placeholder="selectedValue"
      type="search"
      @input="$emit('input', $event)"
      @focus="isFocus = true"
      @blur="isFocus = false"
    >
      <template #prepend-content>
        <div :class="isFocus || value ? 'visible' : 'invisible'" @click="$emit('clear')">
          <CIcon name="md-arrow-back" height="15" />
        </div>
      </template>
      <template #append-content>
        <CIcon name="md-search" />
      </template>
    </CInput>
  </div>
</template>

<script>
/**
 * TheSidebarSearch
 *
 * An async search input which fetches a list of companies/
 * properties from the api.
 *
 * Events:
 *
 * @clear: triggered when input is cleared or back button is clicked
 * @on-results: triggered on api response
 * @search: toggles loading state
 */
import debounce from 'lodash.debounce'

import { RooofAPI } from '@/services/api/resources'

export default {
  name: 'TheSidebarSearch',
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isFocus: false
    }
  },
  computed: {
    selectedValue () {
      const store = this.$store.state.root
      if (store.selectedProperty.name) {
        return store.selectedProperty.name
      } else if (store.selectedCompany.human_name) {
        return store.selectedCompany.human_name
      } else {
        return 'Search'
      }
    }
  },
  watch: {
    value (value) {
      if (value === '') {
        this.$emit('clear')
      } else if (value.length > 2) {
        this.$emit('search', true) // trigger spinner immediately
        this.search()
      }
    }
  },
  methods: {
    /**
     * Search for a company and/or property by name.
     *
     * Calls to this method will be debounced to wait
     * for the user to finish typing before data is
     * requested from the api.
     */
    search: debounce(async function () {
      const params = { q: this.value }
      const response = await RooofAPI.properties.search({ params })
      this.$emit('on-results', response.data)
      this.$emit('search', false)
    }, 400)
  }
}
</script>

<style lang="scss" scoped>
$padding-spacer: 0.5rem;

::v-deep .form-group {
  margin-bottom: 0;

  .input-group {
    .form-control {
      @include remove-borders((top, right, left));
      padding: 1.455rem 0rem;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 0;

      &:focus {
        box-shadow: none;
        border-bottom: 1px solid $primary;
      }
    }

    .input-group-append, .input-group-prepend {
      .input-group-text {
        @include remove-borders((top, right, left));
        border-radius: 0;
        background-color: #fff;
      }
    }

    .input-group-append {
      .input-group-text {
        padding-left: $padding-spacer;
        padding-right: $padding-spacer;
      }
    }
    .input-group-prepend {
      .input-group-text {
        padding-left: $padding-spacer;
        padding-right: $padding-spacer;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
