<template>
  <CSidebarNav id="sidebar-nav">
    <div v-if="backButton" class="back-btn py-2">
      <CSidebarNavItem
        :name="backButton.name"
        :to="backButton.to"
        :label="true"
        icon="md-arrow-back"
      />
    </div>
    <div v-for="navItem in sidebarNavItems" :key="navItem.props.name">
      <component
        :is="navItem.type"
        v-if="navItem.hasPermission === undefined ? true : navItem.hasPermission"
        v-bind="navItem.props"
      />
    </div>
  </CSidebarNav>
</template>

<script>
import { mapGetters } from 'vuex'

/**
 * TheSidebarNav
 *
 * Dynamically renders sidebar navigation items based
 * on the active route.
 *
 * The `hasPermission` prop can be used to conditionally
 * render routes based on user permissions.
 */

// Sidebar nav configurations
const SIDEBAR_INDEX = {
  COMPANY_LIST: 'COMPANY_LIST',
  COMPANY_SELECTED: 'COMPANY_SELECTED',
  PROPERTY_SELECTED: 'PROPERTY_SELECTED',
  STAFF: 'STAFF'
}

export default {
  name: 'TheSidebarNav',
  computed: {
    ...mapGetters({
      hasCompanyPermission: 'auth/hasCompanyPermission'
    }),
    sidebarIndex () {
      return this.$route.matched.map(route => {
        // Need to match on path instead of name because most
        // parent routes use a default child (and have no name)
        switch (route.path) {
          case '/companies':
            return SIDEBAR_INDEX.COMPANY_LIST
          case '/companies/:cid':
            return SIDEBAR_INDEX.COMPANY_SELECTED
          case '/companies/:cid/properties/:pid':
            return SIDEBAR_INDEX.PROPERTY_SELECTED
          case '/staff':
            return SIDEBAR_INDEX.STAFF
          default:
            return null
        }
      }).filter(route => route !== null).slice(-1).pop()
    },
    routeName () {
      return this.$route.name
    },
    userHasMultipleCompanies () {
      return this.$store.state.rooof.companies.length > 1
    },
    backButton () {
      switch (this.sidebarIndex) {
        case SIDEBAR_INDEX.COMPANY_SELECTED:
          return this.userHasMultipleCompanies ? { name: 'Back to Companies', to: '/companies' } : null
        case SIDEBAR_INDEX.PROPERTY_SELECTED: {
          // Prevent navigating back to a subgroup
          if (this.hasCompany()) {
            return {
              name: 'Back to Company',
              to: {
                name: 'PerformanceReport',
                params: { cid: this.$route.params.cid }
              }
            }
          }
          // We still want to show a button, but the navigation
          // will depend on if the user has more than one company
          if (this.userHasMultipleCompanies) {
            return { name: 'Back to Companies', to: '/companies' }
          }
          return { name: 'Back to Company', to: { name: 'Home' } }
        }
        default:
          return null
      }
    },
    reportingNavItems () {
      const features = this.$store.state.root.selectedProperty.features

      const lockedAttrs = {
        class: 'c-sidebar-nav-item--locked',
        icon: 'md-lock'
      }

      const items = [
        {
          name: 'Overview',
          to: { name: 'OverviewReport' }
        },
        {
          name: 'Posted Ads',
          to: { name: 'PostingActivityReport' }
        },
        {
          name: 'Emails',
          to: { name: 'EmailReport' },
          ...features.emailHandling ? {} : lockedAttrs
        },
        {
          name: 'Website Traffic',
          to: { name: 'WebsiteTrafficReport' },
          ...features.redirectLinks ? {} : lockedAttrs
        },
        {
          name: 'Phone Calls',
          to: { name: 'PhoneReport' },
          ...features.callTracking ? {} : lockedAttrs
        }
      ]

      // sort locked items last
      items.sort((a, b) => {
        if (a.icon === b.icon) return 0
        return b.icon ? -1 : 1
      })

      return items
    },
    sidebarNavItems () {
      const hasFullService = this.$store.state.root.selectedProperty.features.postingService
      switch (this.sidebarIndex) {
        case SIDEBAR_INDEX.COMPANY_LIST:
          return [
            {
              type: 'CSidebarNavItem',
              props: { name: 'My Companies', to: { name: 'CompanyList' }, icon: 'md-apartment' }
            }
          ]
        case SIDEBAR_INDEX.COMPANY_SELECTED:
          return [
            {
              type: 'CSidebarNavItem',
              props: {
                name: 'Company Info',
                to: { name: 'CompanyDetail' },
                icon: 'md-info'
              },
              hasPermission: this.hasCompanyPermission(parseInt(this.$route.params.cid))
            },
            {
              type: 'CSidebarNavItem',
              props: {
                name: 'Performance Report',
                to: { name: 'PerformanceReport' },
                icon: 'cil-speedometer'
              }
            },
            {
              type: 'CSidebarNavDropdown',
              props: {
                name: 'Properties',
                icon: 'md-apartment',
                show: true,
                items: [
                  { name: 'Active Properties', to: { name: 'ActivePropertyList' } },
                  { name: 'Inactive Properties', to: { name: 'InactivePropertyList' } }
                ]
              }
            }
          ]
        case SIDEBAR_INDEX.PROPERTY_SELECTED:
          return [
            {
              type: 'CSidebarNavItem',
              props: { name: 'Property Info', to: { name: 'PropertyDetail' }, icon: 'md-info' }
            },
            ...hasFullService ? [{
              type: 'CSidebarNavItem',
              props: {
                name: 'Posting Details',
                to: { name: 'PostingDetail' },
                icon: 'fa-peace',
                class: 'nav-item__posting-details'
              }
            }] : [],
            {
              type: 'CSidebarNavDropdown',
              props: {
                name: 'Reporting',
                icon: 'md-chart-outline',
                class: 'reporting-dropdown',
                show: true,
                items: this.reportingNavItems
              }
            }
          ]
        case SIDEBAR_INDEX.STAFF:
          return [
            {
              type: 'CSidebarNavItem',
              props: { name: 'KPI Report', to: { name: 'KPIReports' }, icon: 'md-chart-outline' }
            },
            {
              type: 'CSidebarNavDropdown',
              props: {
                name: 'Zoho',
                icon: 'zoho-books',
                show: true,
                items: [
                  { name: 'Pending Customers', to: { name: 'PendingZohoCustomer' } },
                  { name: 'Pending Invoices', to: { name: 'PendingZohoInvoice' } }
                ]
              }
            },
            {
              type: 'CSidebarNavDropdown',
              props: {
                name: 'Accounts Receivable',
                icon: 'md-request-quote',
                show: true,
                items: [
                  { name: 'Monthly Summary', to: { name: 'MonthlySummary' } }
                ]
              }
            },
            {
              type: 'CSidebarNavDropdown',
              props: {
                name: 'Invoicing Tools',
                icon: 'md-tools',
                show: true,
                items: [
                  { name: 'Invoice Processing', to: { name: 'InvoiceProcessing' } }
                ]
              }
            },
            {
              type: 'CSidebarNavItem',
              props: { name: 'Price Updating Report', to: { name: 'PriceUpdatingCompanyList' }, icon: 'md-money' }
            },
            {
              type: 'CSidebarNavItem',
              props: { name: 'Subscription Changelog', to: { name: 'SubscriptionChangelog' }, icon: 'md-history' }
            }
          ]
        default:
          return []
      }
    }
  },
  methods: {
    /**
     * Determines if the target company is available to
     * the current user.
     */
    hasCompany () {
      return this.$store.getters['rooof/getCompanyById'](this.$route.params.cid) !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>
// Calculate active sidebar link padding to counter left border width
// added in assets/scss/variables/components/_sidebar.scss
::v-deep {
  // Top-level
  .c-sidebar-nav-link.c-active {
    padding-left: calc(#{$sidebar-nav-link-padding-x} - #{$r-nav-link-active-border-width});
  }
  // Dropdown
  .c-sidebar-nav-dropdown {
    .c-sidebar-nav-dropdown-items {
      $padding-left: calc(#{$sidebar-nav-icon-width} + 15px);

      .c-sidebar-nav-link:not(.c-active) {
        padding-left: $padding-left;
      }
      .c-sidebar-nav-link.c-active {
        padding-left: calc(#{$padding-left} - #{$r-nav-link-active-border-width});
      }
    }
    &.reporting-dropdown .c-sidebar-nav-item--locked {
      .c-sidebar-nav-link {
        color: $gray-400;
        flex-direction: row-reverse;
        justify-content: flex-end;
        .c-sidebar-nav-icon {
          flex-basis: auto;
          margin-left: auto;
        }
        &:not(.c-active) .c-sidebar-nav-icon {
          color: $gray-400;
        }
      }
    }
  }

  .nav-item__posting-details {
    svg {
      width: 15px;
      height: 15px;
    }
  }
}
.back-btn {
  border-bottom: 1px solid $gray-100;
}
</style>
