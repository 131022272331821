<template>
  <div>
    <RSpinnerOverlay
      :loading="loading"
      :is-full-page="true"
      :opacity="1"
    />
    <div v-if="!loading && isAuthenticated" id="portal">
      <AppBanner v-if="showBanner" color="danger">
        Heads up, this domain is changing to <b><a href="https://portal.rooof.com">https://portal.rooof.com</a></b>.
        Please update your bookmarks.
      </AppBanner>

      <RMessage />
      <ContactSupportModal />
      <ErrorModal />

      <div class="c-app">
        <TheSidebar :class="{ 'banner': showBanner }" />
        <CWrapper>
          <TheHeader :class="{ 'banner': showBanner }" />
          <div class="c-body" :class="{ 'banner': showBanner }">
            <main class="c-main">
              <CContainer fluid>
                <transition name="fade">
                  <router-view />
                </transition>
              </CContainer>
            </main>
          </div>
          <TheFooter />
        </CWrapper>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * TheContainer
 *
 * Top-level layout container. Fetches application data and
 * handles redirection to user homepage.
 *
 * All child components are protected by route guard.
 */
import { AppBanner, RMessage } from '@/components/alerts'
import { RSpinnerOverlay } from '@/components/spinners'

import ContactSupportModal from './_components/ContactSupportModal'
import ErrorModal from './_components/ErrorModal'
import TheHeader from './header/TheHeader'
import TheSidebar from './sidebar/TheSidebar'
import TheFooter from './TheFooter'

export default {
  name: 'TheContainer',
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$store.commit('clear', 'selectedCompany')
      vm.$store.commit('clear', 'selectedProperty')
    })
  },
  /**
   * Redirect users to their homepage.
   */
  beforeRouteUpdate (to, from, next) {
    const homepage = this.$store.state.root.homepage
    if (to.name === 'Home' && homepage !== null) {
      next(homepage)
    } else {
      next()
    }
  },
  components: {
    RSpinnerOverlay,
    TheSidebar,
    TheHeader,
    TheFooter,
    AppBanner,
    RMessage,
    ContactSupportModal,
    ErrorModal
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['auth/isAuthenticated']
    },
    showBanner () {
      return window.location.hostname === 'portal-v2.rooof.com'
    }
  },
  /**
   * Load initial data from the api.
   *
   * Actions which fetch data require an auth token,
   * which is why we need to wait until this component
   * has rendered (rather than using App.vue).
   */
  created () {
    this.fetchSync()
    this.fetchAsync()
  },
  methods: {
    /**
     * Fetch application data required for initial load.
     * Display loading spinner until requests have finished.
     */
    async fetchSync () {
      this.loading = true
      await Promise.all([
        this.$store.dispatch('auth/fetchAccount'),
        this.$store.dispatch('rooof/fetchCompanies')
      ])
      // Redirect the user to their homepage. This value is set by fetchCompanies,
      // so we need to wait for the request to finish.
      if (this.$route.name === 'Home') {
        await this.$router.push(this.$store.state.root.homepage)
      }
      this.loading = false
    },
    /**
     * Fetch application data in the background.
     */
    async fetchAsync () {
      this.$store.dispatch('craigslist/fetchRegions')
      this.$store.dispatch('rooof/fetchProducts')
      this.$store.dispatch('rooof/fetchFeatures')
    }
  }
}
</script>

<style lang="scss">
#portal {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .btn {
    box-shadow: none;
    padding: 0.25em 0.75em;
    &:not(.btn-link) {
      border: 1px solid $secondary-25 !important;
    }
  }
  .card {
    box-shadow: none;
    border: 1px solid $gray-200;
  }
  .c-app {
    .c-body.banner {
      position: relative;
    }
    // Adjust for fixed banner height while keeping responsive layout
    .banner {
      top: $banner-height;
    }
  }
}
</style>
