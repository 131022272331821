<template>
  <CSidebar
    id="sidebar"
    unfoldable
    :minimize="minimize"
    :show="show"
    dropdown-mode="openActive"
    color-scheme="light"
    @update:show="value => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <CIcon
        class="c-sidebar-brand-full"
        name="rooofLogo"
        size="custom-size"
        :height="55"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        name="rooofIcon"
        size="custom-size"
        :height="40"
      />
    </CSidebarBrand>
    <div class="sidebar-content">
      <TheSidebarSearch
        v-model="searchValue"
        @search="searching = $event"
        @clear="clear"
        @on-results="searchResults = $event"
      />
      <TheSidebarSearchResults
        v-if="searchValue"
        :loading="searching"
        :search-value="searchValue"
        :data="searchResults"
        @on-select="handleSelect"
      />
      <TheSidebarNav v-else />
    </div>
  </CSidebar>
</template>

<script>
/**
 * TheSidebar
 *
 * Root sidebar component.
 * Supports responsive design.
 */
import { mapState } from 'vuex'

import TheSidebarNav from './TheSidebarNav'
import TheSidebarSearch from './TheSidebarSearch'
import TheSidebarSearchResults from './TheSidebarSearchResults'

export default {
  name: 'TheSidebar',
  components: {
    TheSidebarNav,
    TheSidebarSearch,
    TheSidebarSearchResults
  },
  data () {
    return {
      searchValue: '',
      searching: false,
      searchResults: []
    }
  },
  computed: mapState({
    show: state => state.root.sidebarShow,
    minimize: state => state.root.sidebarMinimize
  }),
  methods: {
    /**
     * Clears search value input and results.
     */
    clear () {
      this.searchValue = ''
      this.searchResults = []
    },
    /**
     * onSelect handler for navigation search
     * results link click.
     *
     * @param {String} value - company/property name
     */
    handleSelect (value) {
      this.clear()
    }
  }
}
</script>

<style lang="scss" scoped>
#sidebar {
  // Override elevation set in @coreui/coreui-pro/scss/_sidebar.scss
  // (it creates a visible line separating sidebar brand and the header)
  box-shadow: none;

  .c-sidebar-brand {
    border-bottom: 1px solid $gray-200;
  }
  .sidebar-content {
    height: 100%;
    box-shadow: 0 2px 2px 0 rgba(60, 75, 100, .14), 0 3px 1px -2px rgba(60, 75, 100, .12), 0 1px 5px 0 rgba(60, 75, 100, .20);
  }
}
</style>
