<template>
  <div class="r-spinner-overlay">
    <LoadingOverlay
      :active.sync="loading"
      :can-cancel="canCancel"
      :on-cancel="onCancel"
      :is-full-page="isFullPage"
      :transition="transition"
      :color="color"
      :height="height"
      :width="width"
      :loader="loader"
      :background-color="backgroundColor"
      :opacity="opacity"
      :z-index="zIndex"
    />
    <slot />
  </div>
</template>

<script>
import 'vue-loading-overlay/dist/vue-loading.css'

import LoadingOverlay from 'vue-loading-overlay'

export default {
  name: 'RSpinnerOverlay',
  components: {
    LoadingOverlay
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    canCancel: {
      type: Boolean,
      default: false
    },
    onCancel: {
      type: Function,
      default: undefined
    },
    isFullPage: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: 'fade' // https://vuejs.org/v2/guide/transitions.html
    },
    color: {
      type: String,
      default: '#2f75b3' // rooof blue
    },
    height: {
      type: Number,
      default: undefined
    },
    width: {
      type: Number,
      default: undefined
    },
    loader: {
      type: String,
      default: 'spinner' // spinner, dots, bars
    },
    backgroundColor: {
      type: String,
      default: '#fafafd' // coreui $light
    },
    opacity: {
      type: Number,
      default: 0.0
    },
    zIndex: {
      type: Number,
      default: 9999
    }
  }
}
</script>
