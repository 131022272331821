<template>
  <CDropdown
    in-nav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <CIcon name="md-account-circle" height="30" />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="$router.push({ name: 'Profile' })">
      <CIcon name="md-user" height="20" />
      <span class="ml-2">
        Profile
      </span>
    </CDropdownItem>
    <CDropdownItem v-if="$store.state.root.devMode" @click="$router.push({ name: 'DevTools' })">
      <CIcon name="md-construction" height="20" />
      <span class="ml-2">
        Dev Tools
      </span>
    </CDropdownItem>
    <CDropdownDivider />
    <CDropdownItem @click="logout">
      <CIcon name="md-logout" height="20" />
      <span class="ml-2">
        Log Out
      </span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  methods: {
    logout () {
      this.$router.push({ name: 'LogOut' })
    }
  }
}
</script>

<style lang="scss" scoped>
.c-avatar {
  opacity: $header-icon-opacity-inactive;
}
.show .c-avatar {
  opacity: $header-icon-opacity-active;
}
</style>
