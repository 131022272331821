<template>
  <CModal
    :show="$store.state.support.show"
    :centered="true"
    :close-on-backdrop="false"
    size="lg"
    title="Contact Support"
    @update:show="close"
  >
    <RSpinnerOverlay :loading="$store.state.support.loading" :opacity="0.7">
      <CForm>
        <CRow>
          <CCol>
            <CInputCheckbox
              :checked.sync="$v.incorrect_profile.$model"
              :label="$store.state.support.labels['incorrect_profile']"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInputCheckbox
              :checked.sync="$v.incorrect_company_property_info.$model"
              :label="$store.state.support.labels['incorrect_company_property_info']"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInputCheckbox
              :checked.sync="$v.incorrect_property_access.$model"
              :label="$store.state.support.labels['incorrect_property_access']"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInputCheckbox
              :checked.sync="$v.incorrect_reporting_data.$model"
              :label="$store.state.support.labels['incorrect_reporting_data']"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInputCheckbox
              :checked.sync="$v.error_code.$model"
              :label="$store.state.support.labels['error_code']"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInputCheckbox
              :checked.sync="$v.other.$model"
              :label="$store.state.support.labels['other']"
            />
          </CCol>
        </CRow>
        <div
          v-if="$v.checkboxGroup.$anyError && !$v.checkboxGroup.checkboxValidator"
          class="invalid-feedback checkbox"
        >
          At least one checkbox is required
        </div>
        <CRow class="mt-2">
          <CCol>
            <CTextarea
              v-model.trim="$v.description.$model"
              :is-valid="validateField('description')"
              label="Can you tell us more about the problem you're experiencing?"
              invalid-feedback="This field must not exceed 2048 characters"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.email.$model"
              :is-valid="validateField('email')"
              add-label-classes="required"
              label="What email address can we contact you at?"
            >
              <template #invalid-feedback>
                <div v-if="!$v.email.required" class="invalid-feedback">
                  This field is required
                </div>
                <div v-if="!$v.email.email" class="invalid-feedback">
                  This field must be an email
                </div>
              </template>
            </CInput>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CInput
              v-model.trim="$v.phone.$model"
              :is-valid="validateField('phone')"
              label="What phone number can we contact you at?"
              invalid-feedback="This field must not exceed 32 characters"
            />
          </CCol>
        </CRow>
      </CForm>
    </RSpinnerOverlay>
    <template #footer>
      <CButton @click="close">
        Cancel
      </CButton>
      <CButton color="primary" :disabled="$store.state.support.loading" @click="submit">
        Send
      </CButton>
    </template>
  </CModal>
</template>

<script>
/**
 * ContactSupportModal
 *
 * State is controlled by the `support` module in
 * vuex. To show the modal, do
 *
 *   this.$store.dispatch('support/open')
 *
 */
import { validationMixin } from 'vuelidate'
import { email, maxLength, required } from 'vuelidate/lib/validators'

import { RSpinnerOverlay } from '@/components/spinners'

/**
 * Validation function to ensure at least one checkbox is checked
 */
const checkboxValidator = (val, vm) => {
  const checkboxes = Object.keys(vm.$store.state.support.labels).map(label => vm[label])
  return checkboxes.some(checkbox => !!checkbox)
}

export default {
  name: 'ContactSupportModal',
  components: {
    RSpinnerOverlay
  },
  mixins: [validationMixin],
  validations: {
    incorrect_profile: { checkboxValidator },
    incorrect_company_property_info: { checkboxValidator },
    incorrect_property_access: { checkboxValidator },
    incorrect_reporting_data: { checkboxValidator },
    error_code: { checkboxValidator },
    other: { checkboxValidator },
    checkboxGroup: [
      'incorrect_profile',
      'incorrect_company_property_info',
      'incorrect_property_access',
      'incorrect_reporting_data',
      'error_code',
      'other'
    ],
    description: { maxLength: maxLength(2048) },
    email: { required, email },
    phone: { maxLength: maxLength(32) }
  },
  computed: {
    incorrect_profile: {
      get () {
        return this.$store.state.support.incorrect_profile
      },
      set (value) {
        this.$store.commit('support/set', ['incorrect_profile', value])
      }
    },
    incorrect_company_property_info: {
      get () {
        return this.$store.state.support.incorrect_company_property_info
      },
      set (value) {
        this.$store.commit('support/set', ['incorrect_company_property_info', value])
      }
    },
    incorrect_property_access: {
      get () {
        return this.$store.state.support.incorrect_property_access
      },
      set (value) {
        this.$store.commit('support/set', ['incorrect_property_access', value])
      }
    },
    incorrect_reporting_data: {
      get () {
        return this.$store.state.support.incorrect_reporting_data
      },
      set (value) {
        this.$store.commit('support/set', ['incorrect_reporting_data', value])
      }
    },
    error_code: {
      get () {
        return this.$store.state.support.error_code
      },
      set (value) {
        this.$store.commit('support/set', ['error_code', value])
      }
    },
    other: {
      get () {
        return this.$store.state.support.other
      },
      set (value) {
        this.$store.commit('support/set', ['other', value])
      }
    },
    description: {
      get () {
        return this.$store.state.support.description
      },
      set (value) {
        this.$store.commit('support/set', ['description', value])
      }
    },
    email: {
      get () {
        return this.$store.state.support.user_email
      },
      set (value) {
        this.$store.commit('support/set', ['user_email', value])
      }
    },
    phone: {
      get () {
        return this.$store.state.support.user_phone
      },
      set (value) {
        this.$store.commit('support/set', ['user_phone', value])
      }
    }
  },
  methods: {
    /**
   * Validate a form field.
   *
   * @param {String} fieldName
   * @returns {Boolean}
   */
    validateField (fieldName) {
      const field = this.$v[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !field.$invalid
    },
    /**
     * Resets validation and dispatches close action.
     */
    close () {
      this.$v.$reset()
      this.$store.dispatch('support/close')
    },
    /**
     * Validates and submits form
     */
    async submit () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      try {
        this.$store.commit('support/set', ['loading', true])
        await this.$store.dispatch('support/submit')
        this.close()
        this.$store.commit('notification/show', {
          message: 'Thank you! Our support team will be in touch soon.',
          type: 'success'
        })
      } catch (err) {
        this.$store.commit('support/set', ['loading', false])
        this.close()
        this.$store.dispatch('error/show', err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .required::after {
  content: '*';
}
.invalid-feedback.checkbox {
  display: block;
}
</style>
