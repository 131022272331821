<template>
  <CHeader id="header" with-subheader>
    <CHeaderBrand class="mx-auto d-lg-none" :to="{ name: 'Home' }">
      <CIcon name="rooofIcon" height="48" alt="Logo" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem
        v-for="link in navLinks"
        :key="link.name"
        class="px-3"
      >
        <CHeaderNavLink :to="link.to">
          {{ link.name }}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav>
      <TheHeaderDropdownHelp />
    </CHeaderNav>
    <CHeaderNav>
      <TheHeaderDropdownAccnt />
    </CHeaderNav>

    <CSubheader>
      <RBreadcrumbRouter class="border-0 mb-0" />
    </CSubheader>
  </CHeader>
</template>

<script>
import { RBreadcrumbRouter } from '@/components/breadcrumbs'

import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderDropdownHelp from './TheHeaderDropdownHelp'

// Header navigation links for staff users
const staffNav = [
  { name: 'Customers', to: { name: 'CompanyList' } },
  { name: 'Staff', to: { name: 'KPIReports' } }
]
// Header navigation links for non-staff users
const userNav = [
  { name: 'Home', to: { name: 'Home' } }
]

export default {
  name: 'TheHeader',
  components: {
    RBreadcrumbRouter,
    TheHeaderDropdownAccnt,
    TheHeaderDropdownHelp
  },
  computed: {
    navLinks () {
      return this.$store.getters['auth/isStaff'] ? staffNav : userNav
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  box-shadow: none;

  .c-header-nav-item {
    height: 100%;
    display: flex;
    align-items: center;

    .router-link-active {
      height: inherit;
      color: $header-light-active-color;
      border-bottom: 5px solid $info;
    }
  }
}
</style>
