<template>
  <CModal
    :show="showModal"
    :centered="true"
    :close-on-backdrop="false"
    title="Error"
    @update:show="close"
  >
    <CCol>
      <CRow class="mb-2">
        {{ errorMessage }}
      </CRow>
      <CRow>
        <CLink
          class="info-btn"
          @click="showInfo = !showInfo"
        >
          More Info
          <CIcon :name="showInfo ? 'md-expand-more' : 'md-chevron-right'" />
        </CLink>
      </CRow>
      <CRow>
        <CCollapse :show="showInfo" class="info">
          <CCard body-wrapper>
            <template v-if="error && error.statusText">
              <h6>Error</h6>
              <p>{{ error.statusText }}</p>
            </template>
            <template v-if="error && error.status">
              <h6>Status</h6>
              <p>{{ error.status }}</p>
            </template>
            <template v-if="error && error.data">
              <h6>Data</h6>
              <p class="error-data">
                {{ error.data }}
              </p>
            </template>
          </CCard>
        </CCollapse>
      </CRow>
    </CCol>
    <template #footer>
      <CButton color="primary" @click="close">
        Close
      </CButton>
    </template>
  </CModal>
</template>

<script>
import Bugsnag from '@bugsnag/js'
import { mapState } from 'vuex'

export default {
  name: 'ErrorModal',
  data () {
    return {
      showInfo: false
    }
  },
  computed: {
    ...mapState({
      showModal: state => state.error.showModal,
      error: state => state.error.error
    }),
    errorMessage () {
      if (!this.error) {
        return ''
      }
      // If we get a 502 error on the Company Performance Report, display a custom message and log the error
      if (this.error.message === 'Network Error' && this.error.config && this.error.config['url'] === `/reports/companies/${this.$route.params.cid}/leads/`) {
        Bugsnag.notify(this.error, event => {
          event.addMetadata('company', { company: this.$route.params.cid })
        })
      }
      switch (this.error.status) {
        case 500:
          return 'Ooops! It looks like something went wrong on our end. Please try again in a few minutes. If you continue to experience this issue please contact our support team.'
        case 401:
          return 'Your session has timed out. Please log in to continue.'
        default:
          return 'Something went wrong, please try again. If you continue to experience this issue please contact our support team.'
      }
    }
  },
  methods: {
    /**
     * Handler for when the modal is closed.
     * If the error is a 401, redirect to the login page
     * after the modal closes.
     */
    close () {
      if (this.error.status === 401) {
        this.$store.dispatch('auth/logout')
      }
      this.$store.dispatch('error/close')
      this.showInfo = false
    }
  }
}
</script>

<style lang="scss" scoped>
.info-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
}
.info {
  width: 100%;
}
.error-data {
  max-height: 300px;
  overflow: auto;
  background-color: $gray-50;
}
</style>
