<template>
  <CAlert
    class="r-message"
    :show="$store.state.notification.show"
    :color="$store.state.notification.type"
    :fade="fade"
    :close-button="closeButton"
    @update:show="$store.commit('notification/set', ['show', $event])"
  >
    {{ $store.state.notification.message }}
  </CAlert>
</template>

<script>
/**
 * RMessage
 *
 * Temporary notification message for user feedback
 * similar to a snackbar.
 *
 * State is controlled by the `notification` module in
 * vuex. To render a notification, commit the `show`
 * mutation from any component.
 *
 *   this.$store.commit('notification/show', payload)
 *
 * Where payload is an object with the following properties:
 *   message: (required) the message to display
 *   type: (default='info') the style of the message (primary, warning, etc)
 *   show: (default=3) the duration to display the message (in seconds)
 */
export default {
  name: 'RMessage',
  props: {
    closeButton: {
      type: Boolean,
      default: false
    },
    fade: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.r-message {
  z-index: 1080;
  margin-bottom: 0;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  position: fixed;
  top: 8px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.95rem;
}
</style>
